'use client'

import LayoutWithNav from '@lyra/web/components/layout/LayoutWithNav'
import useSelectedTabPage from '@lyra/web/hooks/useSelectedTabPage'
import React, { useMemo } from 'react'

import useTabs from '../../hooks/useTabs'
import { TabId } from '../../utils/tabs'

type Props = {
  children: React.ReactNode
}

const StatsLayoutHelper = ({ children }: Props) => {
  const tab = useTabs()[TabId.Activity]
  const selectedPage = useSelectedTabPage()

  const pages = useMemo(() => {
    return tab.pages.map((page) => ({ ...page, isSelected: selectedPage?.id === page.id }))
  }, [tab.pages, selectedPage])

  return (
    <LayoutWithNav height="100%" pages={pages}>
      {children}
    </LayoutWithNav>
  )
}

export default StatsLayoutHelper
